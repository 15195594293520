<template>
  <div class="screen-height d-flex justify-center">
    <div class="all-width">
      <v-card class="shadow-off " loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text class="pt-0 px-0">
          <div class="">
            <div class="flex-grow-1 d-block">
              <pbc v-if="metadata.pbcproject && !loading" :embed="true" api="/v2/financialclose/pbc/" :reflist="formattedreflist" :embedid="metadata.pbcproject" :metadata="metadata" :userlist="formdata.users" :checklistdata="checklist"/>
              <div v-else class="ma-4">
                <div class="d-flex align-center pa-2 hoverable">
                  <span class="mr-4">Information Manager Checklist</span>
                  <lb-file v-model="pbcfile" label="" class="py-0 my-0 " 
                    :loading="fileprocessing['__pbc'] === 1"  v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))" :drag="false" hidedetails @change="readPbcFile('__pbc')" :displayname="false"
                    accept=".xlsx,.xls,.xlsm,.xlsb"/>
                    <v-btn small  class="ml-1 border-on shadow-off black white--text" style="border-radius: 8px !important; " @click="$nova.downloadFile('', {}, '/v2/financialclose/analysis/getinfomanagertemplate')">Download Template</v-btn>
                  <v-spacer></v-spacer>
                  <v-chip v-if="!metadata.pbcproject" color="info" label small>Pending</v-chip>
                  <v-chip v-else color="success" label small>Uploaded</v-chip>
                </div>
                <v-divider></v-divider>
              </div>
            </div>
          </div>

        </v-card-text>
      </v-card>


      <lb-drawer v-model="pbcdialog" :Maxwidth="(pbcuploaddata.length > 0) ? '1665' : '700'"
        heading="Bulk Upload" :loading="loading">
        <template v-slot:body>
          <div>
            <v-simple-table  class="radius-small border-on simple-table " >
              <template v-slot:default>
                <thead class="grayBackground">
                  <th class="text-left text-capitalize  pa-2 border-right-data grayBackground">Tag</th>
                  <th class="text-left text-capitalize  pa-2 border-right-data grayBackground">Category</th>
                  <th class="text-left text-capitalize  pa-2 border-right-data grayBackground">Description</th>
                  <th class="text-left text-capitalize  pa-2 border-right-data grayBackground">Info Owner Name</th>
                  <th class="text-left text-capitalize  pa-2 border-right-data grayBackground">Info Owner Email</th>
                  <th class="text-left text-capitalize  pa-2 border-right-data grayBackground">File Upload</th>
                  <th class="text-left text-capitalize  pa-2 border-right-data grayBackground">File Upload Link</th>
                  <th class="text-left text-capitalize  pa-2 border-right-data grayBackground">Urgent</th>
                  <th class="text-left text-capitalize  pa-2  grayBackground border-on">Due Date</th>
                </thead>
                <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                  <tr v-for="(v,k) in pbcuploaddata" :key="k" class="">
                    <td class="pa-0 align-center d-flex justify-center">
                        <v-tooltip v-if="((pbcdataerror[k] || [])[0] || [])[0] !== ''" bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on"  class="all-width all-height" style="border: 1px solid #F3B9BA">
                              <v-chip v-for="(pv,pk) in ((v.data[0] || {}).tag || [])"   label  :key="pk" color="lightpurple" class="caccounting--text pl-0 mb-1 mr-1">
                                <span class="pl-1">{{pv}}</span>
                              </v-chip>
                            </div>
                          </template>
                          <span>{{((pbcdataerror[k] || [])[0] || [])[0]}}</span>
                        </v-tooltip>
                        <v-chip small v-else  v-for="(pv,pk) in ((v.data[0] || {}).tag || [])" label :key="pk" color="lightpurple" class="caccounting--text pl-0 mb-1 mr-1">
                          <span class="pl-1">{{pv}}</span>
                        </v-chip>
                    </td>
                    <td class="">{{(v.data[0] || {}).category || "Others"}}</td>
                    <td v-if="((pbcdataerror[k] || [])[1] || [])[0] !== '' && false" class="error--text font-weight-bold">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).description || 'Error'}}</div>
                        </template>
                        <span>{{((pbcdataerror[k] || [])[1] || [])[0]}}</span>
                      </v-tooltip>
                    </td>
                    <td class="" v-else>{{(v.data[0] || {}).description}}</td>
                    <td v-if="((pbcdataerror[k] || [])[2] || [])[1] !== '' && false" class=" error--text font-weight-bold">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).info_owner_name}}</div>
                        </template>
                        <span>{{((pbcdataerror[k] || [])[2] || [])[1]}}</span>
                      </v-tooltip>
                    </td>
                    <td class="" v-else>{{(v.data[0] || {}).info_owner_name}}</td>
                    <td v-if="((pbcdataerror[k] || [])[2] || [])[1] !== '' && false" class=" error--text font-weight-bold">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).info_owner_email}}</div>
                        </template>
                        <span>{{((pbcdataerror[k] || [])[2] || [])[1]}}</span>
                      </v-tooltip>
                    </td>
                    <td class="" v-else>{{(v.data[0] || {}).info_owner_email}}</td>
                    <td class="">{{(v.data[0] || {}).file_upload}}</td>
                    <td class="">{{(v.data[0] || {}).file_upload_link}}</td>
                    <td class="">{{(v.data[0] || {}).urgent}}</td>
                    <td class="">{{$nova.formatDate((v.data[0] || {}).due_date)}}</td>
                  </tr>
                </v-slide-y-transition>
              </template>
            </v-simple-table> 
            <div class="ml-2 error--text mt-2" v-if="pbcerrorflag" >We found few errors. Rectify the same to proceed to upload</div>
          </div>
          </template>

          <template v-slot:actions>
            <v-btn small class="ml-2 white--text" color="black" v-if="!pbcerrorflag" @click="uploadPbcData()">Verify & Upload</v-btn>
            </template>
        </lb-drawer>

     

    </div>
  </div>
</template>

<script>
import pbc from '../../../pbc/views/ProjectViewNew';

export default {
  name: 'financialclose_project_informationmanager',
  // props: ['props'],
  data: function(){
    return {
      right:"financialclose_pbc_info_owner",
      metadata: {},
      tb: [],
      checklist: [],
      reflist: [],
      summary: {},
      pbcdata: {},
      fileprocessing: {},
      userid: "",
      formdata: {},
      userlist: {},
      stage: -1,      
      files: {},
      id: "",
      loading: false,
      pbcfile: null,
      pbcdialog: false,
      pbcloading: false,
      pbcerrorflag: false,
      pbcuploaddata: [],
      pbcdataerror: [],
      alluserlist:[]
    }
  },
  components: {
    pbc,
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
 

  methods: {
    refreshData() {
      this.id = this.$route.params.id || "";
      this.userid = this.$store.state.user.id;
      this.getData();
    },
    getData() {
      this.loading = true;
     
      return this.axios.post("/v2/financialclose/analysis/getdetails/"+this.id).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let data = dt.data.data[0] || {}
          this.metadata = data.metadata || {};
          this.stage = this.metadata.stage;
          this.tb = data.tb || [];
          let tbtotals = {};
          for (const i of this.tb) {
            for (const j of (i.reference_detail || [])) {
              tbtotals[j.ref] = tbtotals[j.ref] || {total: 0, items: []};
              tbtotals[j.ref].items.push(i);
              tbtotals[j.ref].total = tbtotals[j.ref].total + i.closing_amount || 0;
            }
          }
          this.pbcdata = {};
          let pbccount = {};
          for (const i of (data.pbcitems || [])) {
            this.pbcdata[i._id] = i;
            for (const j of (i.reference || [])) {
              pbccount[j] = pbccount[j] || {total: 0, completed: 0};
              pbccount[j].total++;
              if(i.stage === 2) pbccount[j].completed++;
            }
          }

          this.checklist = data.checklist || [];
          for (const x of this.checklist) {
            this.reflist.push(x.ref);
            x.__pbccount = pbccount[x._id] || {};
            // if(pbccount[x._id]) console.log(pbccount[x._id]);
            x.__tbtotals = tbtotals[x.ref] || {};
          }
          this.summary = data.summary || {};
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).then(() => {
        return this.axios.post("/v2/financialclose/analysis/getformdata");
      }).then(dt => {
        if(dt.data.status === "success"){
          this.formdata = dt.data.data[0] || {};

          this.userlist = {};
          let colors = this.$nova.getColors((this.formdata.users || []).length, 0.2);
          // console.log(colors);
          for (let i = 0; i < (this.formdata.users || []).length; i++) {
            const el = (this.formdata.users || [])[i];
            this.userlist[el._id] = el;
            this.userlist[el._id].color = colors[i];            
          }
        }else throw new Error(dt.data.message);
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
      }).finally(() => {
        this.loading = false;
      })
    },
    readPbcFile(key){
      let file = this.pbcfile;
      if((file || {}).name && (this.fileprocessing[key] || 0) === 0){
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if(["xlsx","xls","xlsm","xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if(JSON.stringify(dt[0] || []) === JSON.stringify(['Checklist Tag', 'Description', 'Info Owner Name', 'Info Owner Email','File Upload','File Upload Link','Urgent','Due Date'])){
            let grouplist = [];
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                let gname = (i).toString();
                if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
              }
            }
            let groupdata = {};
            let grouporder = [];
            let errordata = {};
            this.pbcerrorflag = false;
            let gstartnum = 1;
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                const el = dt[i];
                let gname = (i).toString();
                if(gname === ""){
                  let newgname = "g"+(gstartnum || "").toString();
                  while(grouplist.indexOf(newgname) !== -1) {
                    newgname = "g"+(gstartnum || "").toString();
                    gstartnum = gstartnum+1;
                  }
                  gname = newgname;
                  if(grouplist.indexOf(gname) === -1) grouplist.push(gname);
                }
                groupdata[gname] = groupdata[gname] || [];
                let pushdata = {"group": gname, "tag": (el[0] || "").split(",").filter(x => x), "description": el[1], "info_owner_name": el[2], "info_owner_email": el[3], "file_upload": el[4], "file_upload_link": el[5], "urgent": el[6], "due_date": el[7]};
                pushdata.urgent = (pushdata.urgent === "Yes")?true:false;
                pushdata.type = (pushdata.file_upload === "Yes")?(((pushdata.file_upload_link || "") === "")?"fileupload":"fileuploadtolink"):"text";
                if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
                if(grouporder.indexOf(gname) === -1) grouporder.push(gname)
                errordata[gname] = errordata[gname] || [];
                let errors = ["","",""];
                let categorylist = {};

                for (const i of this.checklist) {
                  categorylist[i.ref] = i.category;
                }
                let tags = pushdata.tag;
                if(tags.length > 1) pushdata.category = "Multiple";
                else if(tags.length === 1) pushdata.category = categorylist[tags[0]];
                groupdata[gname].push(pushdata);
                
                for (const x of tags) {
                  if(this.reflist.indexOf(x) === -1) errors[0] = "Tag '"+x+"' not found in checklist";
                }
              
                if((el[1] || "").toString() === "") errors[1] = "Description is required";
                if((el[2] || "").toString() === "") errors[2] = "Info Owner Name is required";
                if((el[3] || "").toString() === "") errors[3] = "Info Owner Email is required";
                if(errors.join("") !== "") this.pbcerrorflag = true;
                errordata[gname].push(errors);
              }
            }
            this.pbcuploaddata = [];
            this.pbcdataerror = [];
            for (const i of grouporder) {
               this.pbcuploaddata.push({'group': i, data: groupdata[i]})
              this.pbcdataerror.push(errordata[i]);  
            }

            this.pbcdialog = true;
          }else throw Error("Columns 'Checklist Tag','Description','Info Owner Name','Info Owner Email','File Upload','File Upload Link','Urgent','Due Date' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.pbcfile = null;
          this.fileprocessing[key] = 0;
        });
      }
    },
    uploadPbcData(){
      this.pbcloading = true;
      let ndt = [];

      for (const i of this.pbcuploaddata) {
         ndt.push(i.data[0]);
      }
      this.axios.post("/v2/financialclose/analysis/bulkaddpbc/"+this.id, {data: ndt}).then(dt => {
        if(dt.data.status === "success"){
          this.pbcdialog = false;
          this.refreshData();
          this.$store.commit("sbSuccess", "Information Requirements uploaded successfully");
        }else throw dt.data.message || "Error uploading Information Requirements";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.pbcloading = false;
      })
    },
  },
  computed: {
    formattedreflist: function(){
      let dta = [];
      console.log();
      
      for (const i of (this.checklist || [])) {
        dta.push({value: i._id, name: i.description, master_id: i.master_id, ref:i.ref, location:i.location});
      }
      return dta;
    },
  },
  watch: {
  }
}
</script>